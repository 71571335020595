<template>
  <div class="z-3 w-100 h-100" :style="slidePosition">
    <div class="text-dark position-absolute " style="width:125vw;top:50%;left:50%;transform:translate(-50%,-50%)">

      <img src="images/Slide10/1980-big-bg-circle.svg" class="circle10o w-100 h-100" width="1000" height="1000"
        aria-label="big circle" style="transform: scale(0);" />
    </div>
    <div class="text-dark position-absolute" style="width:70%;top:50%;left:50%;transform:translate(-50%,-50%);">

      <img src="images/Slide10/1980-small-bg-circle.svg" class="circle10o w-100 h-100" width="1000" height="1000"
        aria-label="small circle" style="transform: scale(0);" />

    </div>
    <div
      class="position-absolute d-flex flex-column align-items-center justify-content-center rounded-circle circle10 z-4"
      style=" width: 55vw; height: 55vw; top:50%; left:50%; padding: 8vw;  transform: translate(-50%, -50%) scale(0);">
      <div class="d-flex align-items-center justify-content-center border-radius-2r bg-warning " style="padding:3% 10%;">
        <h6 class="font-vw-6 font-w-600 mb-0 d-block fade10" style="letter-spacing: 3vw;
              margin-right:-2.5vw;
              text-align:right;">
          1980
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs"

import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  data() {
    return {
      spiralPercent: 0
    }
  },
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  methods: {
    circle10(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      animation
        .add({
          targets: ".circle10",
          scale: [0, 1],
          duration: 500,
          delay: (el, i) => 1000 + 30 * i,
          easing: "easeOutCubic"
        })
        .add({
          targets: ".fade10",
          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 500,
          easing: "easeInOutSine",
          delay: (el, i) => 200 + 30 * i
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    circle10o(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      animation.add({
        targets: ".circle10o",
        scale: [0, 1],
        duration: 500,
        delay: (el, i) => 5000 + 500 * i,
        easing: "easeOutCubic"
      })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    }
  },
  watch: {
    innerPosition() {
      this.circle10(this.innerPosition)
      this.circle10o(this.innerPosition)
    }
  }
}
</script>

<style></style>
